export class ApiQueryBuilder {
    private baseUrl: string;
    private queryParams: Record<string, any> = {};
    private segments: string[] = [];

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    addSegment(segment: string | number): ApiQueryBuilder {
        if (segment || segment === 0) {
            this.segments.push(segment.toString());
        }

        return this;
    }

    setParam(key: string, value: any): ApiQueryBuilder {
        if (value || value === 0) {
            this.queryParams[key] = value;
        }

        return this;
    }

    buildUrl(): string {
        const path = this.segments.join('/');
        const query = Object.keys(this.queryParams)
            .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(this.queryParams[key])}`)
            .join('&');

        this.queryParams = {};
        this.segments = []
        
        return `${this.baseUrl}${path}${query.length > 0 ? '?' + query : ''}`;
    }
}