export enum Languages {
    en = "English",
    da = "Dansk",
    de = "Deutsch",
    nl = "Dutch"
}

export class SystemLanguage {
    static readonly Languages = Object.entries(Languages).map(([code, name]) => ({ code, name }));
}

