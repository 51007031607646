
export class User {
  Id: string;
  FirstName: string;
  Password: string;
  Email: string;
  PhoneNumber: string;
  LastLogged: Date;
  Language: string;
  Status: number;
}

