import { StatusOverview } from './../model/statusoverview';
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHelperService } from './httphelper.service';
import { Event } from '../model/event';

const API_URL = environment.apiUrl;

@Injectable()
export class ReportService {
    constructor(private http: HttpClient, protected httpHelperService: HttpHelperService) {
    }

    getStatusOverview(departmentId: string) {
        return this.http.get<StatusOverview[]>(`${API_URL}dashboard?departmentId=${departmentId}`, this.httpHelperService.getHttpOptions());
    }
}
