import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpHelperService } from "./httphelper.service";
import { environment } from "../../../environments/environment";
import { Event } from "./../model/event";
import { ActiveEvent } from "../model/activeEvent";
import { HistoricalEvent } from "../model/historicalEvent";
import { ApiQueryBuilder } from "../Utilities/urlQueryBuilder";

export const API_URL = environment.apiUrl;

@Injectable()
export class EventService extends ApiQueryBuilder {
	constructor(
		private http: HttpClient,
		private httpHelperService: HttpHelperService
	) {
		super(API_URL);
	}

	getResidentEvents(residentId: string, month: number, year: number) {
		return this.http.get<Event[]>(
			`${API_URL}event/resident/${residentId}?month=${month}&year=${year}`,
			this.httpHelperService.getHttpOptions()
		);
	}

	getDashboardSummary(departmentId: String) {
		let url = `${API_URL}event/dashboardevents`;

		if (departmentId) {
			url = `${url}?departmentId=${departmentId}`;
		}

		return this.http.get<ActiveEvent[]>(
			url,
			this.httpHelperService.getHttpOptions()
		);
	}

	getDashboardEventsHistory(residentId: Number, activeEventId: Number) {
		const url = this.addSegment("event")
			.addSegment("DashboardEventsHistory")
			.setParam("residentId", residentId);

		if (activeEventId) {
			url.setParam("activeEventId", activeEventId);
		}

		const fullUrl = url.buildUrl();
		return this.http.get<HistoricalEvent[]>(
			fullUrl,
			this.httpHelperService.getHttpOptions()
		);
	}

	getResidentActiveEvents(residentId: number) {
		return this.http.get<ActiveEvent>(
			`${API_URL}event/residentdashboardevents?residentId=${residentId}`,
			this.httpHelperService.getHttpOptions()
		);
	}
}
